// @flow
import React, {PureComponent, type Node} from 'react';
import {getAssetURL} from 'nextjs';

import {Typography, withStyles, type Classes, type Theme} from 'ui';
import {FullWidthContent} from 'layout';
import {colors} from 'theme/v2';
import {Section, SectionOverline, SectionHeadline} from 'site/Section';

const mdWidth = 1160;
const mdMediaQ = `@media (max-width:${mdWidth}px)`;

const styles = (theme: Theme) => ({
  content: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
    '& img': {
      padding: 0,
    },
    '&:nth-child(2n+1) img': {
      order: -1,
      marginRight: theme.spacing(2),
    },
    '&:nth-child(2n) img': {
      order: 1,
      marginLeft: theme.spacing(2),
    },
    [mdMediaQ]: {
      flexDirection: 'column',
      '&:not(:first-child)': {
        marginTop: theme.spacing(3),
      },
      '& img': {
        order: [0, '!important'],
        marginLeft: [0, '!important'],
        marginRight: [0, '!important'],
        marginTop: theme.spacing(3),
      },
    },
    [theme.breakpoints.down('xs')]: {
      '&:not(:first-child)': {
        marginTop: theme.spacing(2),
      },
      '& img': {
        marginTop: theme.spacing(2),
        width: '100%',
        height: 'auto',
      },
    },
  },
  item: {
    width: 532,
    height: 532,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.grey8,
    verticalAlign: 'top',
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    [mdMediaQ]: {
      height: 'auto',
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  text: {
    paddingBottom: theme.spacing(3),
    [mdMediaQ]: {
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0,
    },
  },
  overline: {
    marginBottom: theme.spacing(3),
    fontWeight: 700,
  },
  headline: {
    marginBottom: theme.spacing(1.5),
    fontSize: 24,
  },
  body: {
    fontSize: 18,
    marginBottom: theme.spacing(3),
  },
});

export type ShowcaseItem = {|
  overline: string,
  headline: string,
  body: string,
  link: Node,
  imgFilename: string,
|};

type Props = {
  items: ShowcaseItem[],
  // injected
  classes: Classes<typeof styles>,
};

class Showcase extends PureComponent<Props> {
  render() {
    const {classes, items} = this.props;
    return (
      <Section>
        <FullWidthContent contentClassName={classes.content}>
          {items.map((item, i) => {
            const {overline, headline, body, link, imgFilename} = item;
            return (
              <div key={i} className={classes.row}>
                <div className={classes.item}>
                  <div className={classes.text}>
                    <SectionOverline className={classes.overline}>
                      {overline}
                    </SectionOverline>
                    <SectionHeadline className={classes.headline}>
                      {headline}
                    </SectionHeadline>
                    <Typography className={classes.body}>{body}</Typography>
                    {link}
                  </div>
                </div>
                <img
                  src={getAssetURL(`/images/${imgFilename}`)}
                  alt={imgFilename}
                  className={classes.item}
                />
              </div>
            );
          })}
        </FullWidthContent>
      </Section>
    );
  }
}

export default withStyles(styles)(Showcase);
