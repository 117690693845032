// @flow
import React from 'react';
import Link from 'next/link';

import {SectionUnderlinedLink} from 'site/Section';
import Showcase, {type ShowcaseItem} from 'site/Showcase';

function HomeShowcase() {
  const showcaseItems: ShowcaseItem[] = [
    {
      overline: 'HOW TO GET IT',
      headline: 'Simple setup and installation',
      body: 'We can connect your home and get you online in just a few hours.',
      link: (
        <Link href="/installation" passHref>
          <SectionUnderlinedLink>Learn more</SectionUnderlinedLink>
        </Link>
      ),
      imgFilename: 'jono-ladder-3.jpg',
    },
    {
      overline: 'CUSTOMER SUPPORT',
      headline: 'We treat our customers like people',
      body:
        'Call, text, or chat to get help from friendly local people in real time.',
      imgFilename: 'clint-phillip.jpg',
    },
    {
      overline: 'FOR RENTERS',
      headline: 'Landlords love us',
      body:
        'We work closely with building owners and property managers to bring our customers better service.',
      imgFilename: 'building-owner.jpg',
    },
  ];

  return <Showcase items={showcaseItems} />;
}

export default HomeShowcase;
