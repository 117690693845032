// @flow
import React, {PureComponent} from 'react';

import {withStyles, type Classes, type Theme} from 'ui';
import {colors} from 'theme/v2';
import {FullWidthContent} from 'layout';
import {Section, SectionTextGroup, SectionOverline} from 'site/Section';
import VentureBeat from './VentureBeat.svg';
import SanFranciscoChronicle from './SanFranciscoChronicle.svg';
import WallStreetJournal from './WallStreetJournal.svg';
import FastCompany from './FastCompany.svg';

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: colors.grey7,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  textGroup: {
    maxWidth: 500,
    alignItems: 'center',
    textAlign: 'center',
  },
  overline: {
    marginBottom: theme.spacing(3),
  },
  pressLinks: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  pressLink: {
    height: theme.spacing(3),
    width: 'auto',
    margin: theme.spacing(2.5),
  },
  pressLinkIcon: {
    height: theme.spacing(3),
    width: 'auto',
  },
});

type Props = {
  // injected
  classes: Classes<typeof styles>,
};

class ReadMore extends PureComponent<Props> {
  render() {
    const {classes} = this.props;

    return (
      <Section className={classes.root}>
        <FullWidthContent contentClassName={classes.content}>
          <SectionTextGroup className={classes.textGroup}>
            <SectionOverline className={classes.overline} align="center">
              Read More In
            </SectionOverline>
          </SectionTextGroup>

          <div className={classes.pressLinks}>
            <div className={classes.pressLink}>
              <a
                href="https://www.fastcompany.com/90234124/fast-wireless-alternatives-to-the-big-isps-cant-grow-fast-enough"
                target="_blank"
                rel="noopener noreferrer">
                <FastCompany className={classes.pressLinkIcon} />
              </a>
            </div>
            <div className={classes.pressLink}>
              <a
                href="https://venturebeat.com/2017/06/15/common-networks-raises-7-million-to-bring-high-speed-internet-to-suburban-america/"
                target="_blank"
                rel="noopener noreferrer">
                <VentureBeat className={classes.pressLinkIcon} />
              </a>
            </div>
            <div className={classes.pressLink}>
              <a
                href="https://www.sfchronicle.com/business/article/Bay-Area-Internet-providers-thriving-in-the-era-11200806.php"
                target="_blank"
                rel="noopener noreferrer">
                <SanFranciscoChronicle className={classes.pressLinkIcon} />
              </a>
            </div>
            <div className={classes.pressLink}>
              <a
                href="https://www.wsj.com/articles/common-networks-raises-7-million-series-a-for-wireless-internet-service-1497526200"
                target="_blank"
                rel="noopener noreferrer">
                <WallStreetJournal className={classes.pressLinkIcon} />
              </a>
            </div>
          </div>
        </FullWidthContent>
      </Section>
    );
  }
}

export default withStyles(styles)(ReadMore);
