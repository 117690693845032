// @flow

let assetPrefix: string;

if ((process: any).browser) {
  // runtime
  const nextData = global.__NEXT_DATA__;
  assetPrefix = nextData && nextData.assetPrefix ? nextData.assetPrefix : '';
} else {
  // build time
  assetPrefix =
    process.env.DEPLOY_VERSION && process.env.CWN_PROJECT
      ? `https://storage.googleapis.com/common-net-cdn-2/${
          process.env.DEPLOY_VERSION
        }/${process.env.CWN_PROJECT}`
      : '';
}

export function getAssetURL(path: string): string {
  return `${assetPrefix}${path}`;
}
