// @flow
import React, {PureComponent} from 'react';

import {Typography, withStyles, type Classes, type Theme} from 'ui';
import {colors} from 'theme/v2';
import {FullWidthContent} from 'layout';
import {Section, SectionHeadline, SectionOverline} from 'site/Section';

import StarIcon from '@material-ui/icons/Star';

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: colors.chablis1,
  },
  content: {
    textAlign: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  overline: {
    color: colors.white,
    fontWeight: 700,
  },
  headline: {
    color: colors.white,
    fontSize: 40,
    lineHeight: 1.3,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 740,
    [theme.breakpoints.down('xs')]: {
      fontSize: 32,
      lineHeight: 1.35,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  customer: {
    color: colors.white,
    fontWeight: 700,
    fontSize: 24,
  },
  stars: {
    color: colors.white,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    maxWidth: 140,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  star: {
    fontSize: 28,
  },
});

type Props = {
  // injected
  classes: Classes<typeof styles>,
};

class CustomerReview extends PureComponent<Props> {
  render() {
    const {classes} = this.props;
    return (
      <Section className={classes.root}>
        <FullWidthContent contentClassName={classes.content}>
          <SectionOverline className={classes.overline}>
            CUSTOMER REVIEW
          </SectionOverline>
          <SectionHeadline className={classes.headline}>
            “I've had many ISPs, but this is the first one that I really love.
            Local, homegrown, blazing fast, inexpensive, and just generally
            awesome.”
          </SectionHeadline>
          <Typography className={classes.customer}>Jason H.</Typography>
          <Typography className={classes.stars}>
            <StarIcon className={classes.star} />
            <StarIcon className={classes.star} />
            <StarIcon className={classes.star} />
            <StarIcon className={classes.star} />
            <StarIcon className={classes.star} />
          </Typography>
        </FullWidthContent>
      </Section>
    );
  }
}

export default withStyles(styles)(CustomerReview);
