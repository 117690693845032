// @flow
import React, {PureComponent} from 'react';
import Link from 'next/link';

import {withStyles, type Classes, type Theme} from 'ui';
import {colors} from 'theme/v2';
import {FullWidthContent} from 'layout';
import {
  Section,
  SectionTextGroup,
  SectionHeadline,
  SectionOverline,
  SectionBody1,
  SectionUnderlinedLink,
} from 'site/Section';
import {getAssetURL} from 'nextjs';

const styles = (theme: Theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      minHeight: theme.spacing(94),
      backgroundColor: colors.grey7,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'local',
      backgroundImage: `url(${getAssetURL('/images/installer-sm.jpg')})`,
      backgroundPositionX: 'center',
      backgroundPositionY: '13%',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      alignItems: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(6),
    },
  },
  textGroup: {
    maxWidth: theme.spacing(50),
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      maxWidth: 500,
    },
    [theme.breakpoints.up('lg')]: {
      alignItems: 'start',
    },
  },
  overline: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  headline: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  body1: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  mobileImage: {
    backgroundColor: colors.grey7,
    height: theme.spacing(50),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'local',
    backgroundImage: `url(${getAssetURL('/images/installer-sm.jpg')})`,
    backgroundPositionX: 'right',
    backgroundPositionY: 'center',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(80),
      backgroundPositionX: 'center',
    },
  },
});

type Props = {
  // injected
  classes: Classes<typeof styles>,
};

class OurVision extends PureComponent<Props> {
  render() {
    const {classes} = this.props;

    return (
      <Section className={classes.root}>
        <FullWidthContent contentClassName={classes.content}>
          <SectionTextGroup className={classes.textGroup}>
            <SectionOverline className={classes.overline}>
              Our vision
            </SectionOverline>
            <SectionHeadline className={classes.headline}>
              Your internet service partner
            </SectionHeadline>
            <SectionBody1 className={classes.body1}>
              Common Networks is dedicated to transforming what you expect from
              your home internet.
            </SectionBody1>
            <div>
              <Link href="/about" passHref>
                <SectionUnderlinedLink>Learn more</SectionUnderlinedLink>
              </Link>
            </div>
          </SectionTextGroup>
        </FullWidthContent>
        <div className={classes.mobileImage} />
      </Section>
    );
  }
}

export default withStyles(styles)(OurVision);
