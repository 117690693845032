// @flow
import React, {PureComponent} from 'react';

import {
  Button,
  Paper,
  Typography,
  withStyles,
  type Classes,
  type Theme,
} from 'ui';
import {FullWidthContent} from 'layout';
import {
  Section,
  SectionTextGroup,
  SectionHeadline,
  SectionOverline,
  SectionBody1,
} from 'site/Section';
import {CheckAddressLink} from 'site/CheckAddress';
import {colors} from 'theme/v2';
import {CheckCircleOutline} from 'icons';
import {getAssetURL} from 'nextjs';

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    position: 'relative',
    display: 'flex',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(10),
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  },
  left: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      flex: '1 1 50%',
      paddingRight: theme.spacing(2),
    },
  },
  right: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      width: '100%',
      maxWidth: 500,
    },
    [theme.breakpoints.up('lg')]: {
      flex: '1 1 50%',
      paddingLeft: theme.spacing(2),
    },
  },
  image: {
    maxWidth: 540,
  },
  overline: {
    marginBottom: theme.spacing(3),
  },
  headline: {
    marginBottom: theme.spacing(2),
  },
  body1: {
    color: theme.palette.common.black,
  },
  monthlyPlan: {},
  monthlyPlanHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.grey5,
    [theme.breakpoints.only('xs')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  monthlyPlanHeaderText: {
    fontSize: '24px',
    fontWeight: 700,
    color: theme.palette.common.black,
  },
  monthlyPlanBody: {
    [theme.breakpoints.only('xs')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
    },
  },
  monthlyPlanList: {
    padding: 0,
    margin: 0,
    listStyleType: 'none',
  },
  monthlyPlanListItem: {
    display: 'flex',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.grey4,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between',
    },
  },
  monthlyPlanListItemText: {
    fontSize: '18px',
    fontWeight: 500,
    color: theme.palette.common.black,
  },
  monthlyPlanListItemCheck: {
    color: [colors.bossanova1, '!important'],
    fontSize: '30px',
    flex: '0 0 auto',
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
  monthlyPlanCTA: {
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
      paddingTop: theme.spacing(4),
    },
  },
  signupButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(25),
    },
    background: [colors.persian1, '!important'],
  },
  signupButtonLabel: {
    fontWeight: 700,
  },
});

type Props = {
  // injected
  classes: Classes<typeof styles>,
};

class OurService extends PureComponent<Props> {
  renderMonthlyPlanListItem(text: string) {
    const {classes} = this.props;

    return (
      <li className={classes.monthlyPlanListItem}>
        <Typography className={classes.monthlyPlanListItemText}>
          {text}
        </Typography>
        <CheckCircleOutline className={classes.monthlyPlanListItemCheck} />
      </li>
    );
  }

  render() {
    const {classes} = this.props;
    return (
      <Section className={classes.root}>
        <FullWidthContent contentClassName={classes.content}>
          <SectionTextGroup className={classes.left}>
            <SectionOverline className={classes.overline}>
              Our service
            </SectionOverline>
            <SectionHeadline className={classes.headline} component="h2">
              Fast internet, simple pricing
            </SectionHeadline>
            <SectionBody1 className={classes.body1}>
              Smooth streaming, seamless browsing, and super fast uploads.
              <br />
              <br />
              And everything's included in a flat monthly rate that won’t
              suddenly go up.
            </SectionBody1>
          </SectionTextGroup>
          <div className={classes.right}>
            <img
              className={classes.image}
              src={getAssetURL(`/images/oakland-hero.jpg`)}
              alt="oakland"
            />
          </div>
        </FullWidthContent>
      </Section>
    );
  }
}

export default withStyles(styles)(OurService);
