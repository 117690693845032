// @flow
import React, {PureComponent} from 'react';
import Link from 'next/link';
import classnames from 'classnames';

import {withStyles, type Classes, type Theme} from 'ui';
import {FullWidthContent} from 'layout';
import {
  Section,
  SectionTextGroup,
  SectionHeadline,
  SectionOverline,
  SectionCard,
  SectionCardText,
  SectionUnderlinedLink,
} from 'site/Section';
import {getAssetURL} from 'nextjs';

const styles = (theme: Theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      background: `url(${getAssetURL(
        '/images/cor-roof.jpg',
      )}) no-repeat center top local`,
      backgroundSize: 'cover',
      backgroundPositionX: 'center',
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(20),
    },
  },
  mobileTextAndDesktopCards: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobileText: {},
  mobileTextContent: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
  mobileTextContentWrapper: {
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  mobileOverline: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  mobileHeadline: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  desktopTextContentWrapper: {
    paddingBottom: theme.spacing(3),
    maxWidth: theme.spacing(50),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  desktopOverline: {
    marginBottom: theme.spacing(3),
    color: theme.palette.common.white,
  },
  desktopHeadline: {
    color: theme.palette.common.white,
  },
  desktopCards: {
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      background: `url(${getAssetURL(
        '/images/cor-roof.jpg',
      )}) no-repeat center top local`,
      backgroundSize: 'cover',
      backgroundPositionX: 'center',
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
    },
  },
  desktopCardsContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobileImage: {
    background: `url(${getAssetURL(
      '/images/cor-roof.jpg',
    )}) no-repeat center top local`,
    backgroundSize: 'cover',
    backgroundPositionX: 'right',
    height: theme.spacing(50),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  mobileCards: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  mobileCardsContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  card: {
    [theme.breakpoints.only('xs')]: {
      boxShadow: theme.shadows[8],
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: theme.spacing(40),
    },
  },
  card1: {
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(3),
      maxWidth: theme.spacing(50),
    },
  },
  card2: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.spacing(72),
    },
  },
  card3: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.spacing(50),
    },
  },
  cardRow: {
    display: 'flex',
    [theme.breakpoints.between('sm', 'md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(3),
    },
  },
  learnMoreButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

type Props = {
  // injected
  classes: Classes<typeof styles>,
};

class HowItWorks extends PureComponent<Props> {
  render() {
    const {classes} = this.props;

    const card1 = (
      <SectionCard className={classnames(classes.card, classes.card1)}>
        <SectionCardText>
          Our graph network relays wireless signals from rooftop to rooftop.
        </SectionCardText>
      </SectionCard>
    );

    const card2 = (
      <SectionCard className={classnames(classes.card, classes.card2)}>
        <SectionCardText>
          Every new customer expands the network and makes it a little bit
          stronger for the whole neighborhood.
        </SectionCardText>
      </SectionCard>
    );

    const card3 = (
      <SectionCard className={classnames(classes.card, classes.card3)}>
        <SectionCardText>
          It’s a new choice for super fast speeds, with a community spirit at
          heart.
        </SectionCardText>
        <Link href="/technology" passHref>
          <SectionUnderlinedLink className={classes.learnMoreButton}>
            Learn more
          </SectionUnderlinedLink>
        </Link>
      </SectionCard>
    );

    return (
      <Section className={classes.root}>
        <div className={classes.mobileTextAndDesktopCards}>
          <FullWidthContent
            className={classes.mobileText}
            contentClassName={classes.mobileTextContent}>
            <SectionTextGroup className={classes.mobileTextContentWrapper}>
              <SectionOverline className={classes.mobileOverline}>
                How it works
              </SectionOverline>
              <SectionHeadline className={classes.mobileHeadline}>
                A super fast wireless network
              </SectionHeadline>
            </SectionTextGroup>
          </FullWidthContent>
          <FullWidthContent
            className={classes.desktopCards}
            contentClassName={classes.desktopCardsContent}>
            <SectionTextGroup className={classes.desktopTextContentWrapper}>
              <SectionOverline className={classes.desktopOverline}>
                How it works
              </SectionOverline>
              <SectionHeadline className={classes.desktopHeadline}>
                A super fast wireless network
              </SectionHeadline>
            </SectionTextGroup>
            {card1}
            <div className={classes.cardRow}>{card2}</div>
            {card3}
          </FullWidthContent>
        </div>
        <div className={classes.mobileImage} />
        <FullWidthContent
          className={classes.mobileCards}
          contentClassName={classes.mobileCardsContent}>
          {card1}
          {card2}
          {card3}
        </FullWidthContent>
      </Section>
    );
  }
}

export default withStyles(styles)(HowItWorks);
